<template>
  <transition name="el-zoom-in-center">
    <div class="brain-popover params-popover" v-if="showPopover">
      <div class="popover-mask"></div>
      <div class="popover-content">
        <div class="popover-head">
          <span class="popover-title">{{ $t("service.create_task") }}</span>
          <span class="close-btn" @click="closePopover">
            <i class="el-icon-close"></i>
          </span>
        </div>
        <div class="steps-wrapper shadow-sm">
          <div class="step-item" :class="step == 0 ? 'active' : ''">
            <span class="circle">1</span>
            <span>{{ $t("service.set_param") }}</span>
          </div>

          <div
            class="step-item step-two"
            v-for="(stepItem, index) in stepList"
            :key="index"
            :class="step == index + 1 ? 'active' : ''"
          >
            <span class="circle">{{ index + 2 }}</span>
            <span> {{ stepItem.key_title || stepItem.key_name }} </span>
          </div>

          <div
            class="step-item"
            :class="step == stepList.length + 1 ? 'active' : ''"
          >
            <span class="circle">{{ stepList.length + 2 }}</span>
            <span>{{ $t("project.finish") }}</span>
          </div>
        </div>
        <div class="popover-body">
          <div>
            <div class="section" v-show="step == 0">
              <div class="base-info">
                <div class="my-3">
                  {{ $t("service.base_info") }}
                </div>
                <div class="info-item">
                  <div class="task-name">
                    <span class="info-lable"
                      >{{ $t("service.task_name") }}:</span
                    >
                    <div class="input-box">
                      <el-input v-model="baseInfo.task_name"></el-input>
                    </div>
                  </div>
                </div>
              </div>

              <div class="section" v-if="customParams.length > 0">
                <div class="my-3">
                  {{ $t("service.custom_param") }}
                </div>
                <div class="tab-content">
                  <el-tabs tab-position="left">
                    <el-tab-pane
                      v-for="(item, index) in customParams"
                      :key="item.param_key"
                      :label="item.param_name"
                    >
                      <div v-if="item.param_type === 'text'">
                        <textarea
                          id
                          v-model="formData[index].param_value"
                          style="width: 100%; min-height: 100px"
                        ></textarea>
                      </div>
                      <div v-if="item.param_type === 'single-select'">
                        <el-radio-group v-model="formData[index].param_value">
                          <div
                            v-for="val in item.param_value"
                            :key="val"
                            class="select-item"
                          >
                            <el-radio :label="val"></el-radio>
                          </div>
                        </el-radio-group>
                      </div>
                      <div v-if="item.param_type === 'mult-select'">
                        <el-checkbox-group
                          v-model="formData[index].param_value"
                        >
                          <div
                            v-for="paramVal in item.param_value"
                            :key="paramVal"
                            class="select-item"
                          >
                            <el-checkbox :label="paramVal">{{
                              paramVal
                            }}</el-checkbox>
                          </div>
                        </el-checkbox-group>
                      </div>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </div>
            <!--step one : base params -->

            <div
              v-show="step == index + 1"
              :key="index"
              v-for="(item, index) in stepList"
            >
              <div
                class="project-card my-3"
                v-for="group in tableData"
                :key="group.id"
              >
                <div class="px-3 py-3 card-name border-bottom">
                  <span @click.stop="collapseGroup(group.id)" class="cursor"
                    >{{ group.name }} [ {{ group.subjects.length }} ]</span
                  >
                  <span
                    class="px-2 cursor"
                    @click.stop="collapseGroup(group.id)"
                  >
                    <i
                      :class="
                        group.collapse
                          ? 'fa fa-caret-down'
                          : 'fa fa-caret-right'
                      "
                    ></i>
                  </span>
                </div>

                <el-table
                  ref="multipleTable"
                  :data="group.subjects"
                  tooltip-effect="dark"
                  :highlight-current-row="defaultTrue"
                  size="mini"
                  style="width: 100%"
                  :empty-text="$t('no_data')"
                  :step_name="item.key_name"
                  :group_id="group.id"
                  :default-expand-all="defaultTrue"
                  @select="handleSelectChange"
                  @select-all="handleSelectAllChange(group.id)"
                  :header-cell-class-name="tableHeaderStyle"
                  v-show="group.collapse"
                >
                  <el-table-column type="selection" :selectable="selectable">
                  </el-table-column>
                  <el-table-column type="expand">
                    <template slot-scope="props">
                      <div
                        v-for="(file, i) in props.row.files[item.key_name]"
                        :key="i"
                        class="ml-3 pl-3"
                      >
                        <!--序列文件-->
                        <div class="d-flex align-items-center">
                          <div style="width: 250px">
                            <el-radio-group
                              v-model="radioList[item.key_name][props.row.id]"
                              @input="
                                radioChange(file.id, item.key_name, props.row)
                              "
                            >
                              <el-radio
                                class="radio"
                                :label="file.id"
                                :id="file.id"
                                :disabled="
                                  !radioList[stepList[0].key_name][
                                    props.row.id
                                  ] && item.key_name != stepList[0].key_name
                                "
                                :name="radioList[item.key_name][props.row.id]"
                              >
                                <span class="mr-3">
                                  {{ file.series_name }}</span
                                >
                              </el-radio>
                            </el-radio-group>
                          </div>

                          <div style="width: 110px" class="text-center">
                            <span class="mr-3"> {{ file.series }}</span>
                          </div>

                          <div style="width: 110px" class="text-center">
                            <span class="mr-3"> {{ file.series_date }}</span>
                          </div>
                          <div style="width: 110px" class="text-center">
                            <span class="mr-3">
                              {{ file.body_part_examined }}</span
                            >
                          </div>
                          <div style="width: 110px" class="text-center">
                            {{ file.dimension_value }}
                          </div>
                          <div style="width: 93px" class="text-center">
                            <a
                              class="col-btn view-dicom"
                              target="_blank"
                              :href="
                                url_dicomviewer +
                                '?keyid=' +
                                file.id +
                                '&subjectnumber=' +
                                props.row.subject_name +
                                '&age=' +
                                props.row.subject_age +
                                '&gender=' +
                                props.row.subject_gender +
                                '&requrl=' +
                                url_backendapi +
                                '/api/dicomview/index/&token=&language=' +
                                lang
                              "
                              >{{ $t("_data.view_dicom") }}</a
                            >
                          </div>
                          <div style="width: 100px" class="text-right">
                            {{ formatStatus(file.task_state) }}
                          </div>
                        </div>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="subject_name"
                    :label="$t('_data.series_desc')"
                    title="“序列描述”"
                    width="250px"
                  >
                    <template slot-scope="scope">
                      <span>{{ scope.row.subject_name }}</span>
                      <span style="margin-left: 20px">{{
                        formatGender(scope.row)
                      }}</span>
                      <span style="margin-left: 20px">{{
                        scope.row.subject_age
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('_data.series_type')">
                  </el-table-column>
                  <el-table-column :label="$t('_data.study_date')">
                  </el-table-column>
                  <el-table-column :label="$t('analyse.body_part')">
                  </el-table-column>
                  <el-table-column :label="$t('_data.img_size')">
                  </el-table-column>
                  <el-table-column :label="$t('operate')"> </el-table-column>

                  <el-table-column
                    :label="$t('analyse.status')"
                    :formatter="filterStatus"
                  ></el-table-column>
                </el-table>
              </div>
            </div>
            <!--step two-->

            <div class="step-content" v-show="step == stepList.length + 1">
              <div class="seccess-content">
                <div class="success-icon">
                  <i class="el-icon-circle-check"></i>
                </div>
                <div class="success-text">{{ $t("service.last_step") }}</div>
              </div>
              <div class="button-wrapper text-center">
                <button
                  class="btn btn-primary btn-sm ml10"
                  @click.prevent="closePopover"
                >
                  {{ $t("close") }}
                </button>
              </div>
            </div>
            <!--step three-->
          </div>
        </div>

        <div class="button-wrapper border-top">
          <button
            class="btn btn-sm btn-primary"
            v-show="step > 0 && step <= stepList.length"
            @click.stop="step -= 1"
          >
            {{ $t("service.previous") }}
          </button>

          <button
            v-show="step == stepList.length"
            class="btn btn-primary btn-sm float-right"
            @click.stop="submitParam('modelForm')"
          >
            {{ $t("service.submit_task") }}
          </button>
          <button
            v-show="step < stepList.length"
            class="btn btn-primary btn-sm float-right"
            @click.stop="goNextStep()"
          >
            {{ $t("service.next") }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { Loading } from "element-ui";
import { mapState } from "vuex";

import { batchCreateTaskRequest, projectAppFiles } from "@/api/api";

export default {
  props: {
    Params: {
      type: Object,
      default: () => {
        return {
          project_id: null,
          app_id: null,
          data_type: null,
        };
      },
    },
    showPopover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      radioList: {}, //选中的subject:file.id
      subjectSelection: [], //选中的subject.id
      step: 0,
      formData: {},
      customParams: [],
      baseInfo: {},
      subtypesData: [],
      tableData: [], //病人，符合分析应用条件
      stepList: [], //选择序列类型
      isInfoRequired: false, //是否需要系统年龄性别
      selection_subjects: [],

      defaultTrue: true,
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.language,
      url_dicomviewer: (state) => state.url_dicomviewer, //:28803
      url_voiviewer: (state) => state.url_voiviewer, //:28803
      url_backendapi: (state) => state.url_backendapi, //:28801
      url_adaptorapi: (state) => state.url_adaptorapi, //:28000
    }),
  },
  mounted() {},
  watch: {
    Params(newval, oldval) {
      newval && this.initParams(newval);
    },
    stepList(n, o) {
      if (n) {
        //console.log("batch tasks watch steplist::::::", n);
        //this.stepList = n;
      }
    },
  },
  methods: {
    collapseGroup(groupid) {
      this.tableData.map((t, i) => {
        if (t.id == groupid) {
          t.collapse = !t.collapse;
        }
      });
    },
    initParams(val) {
      Object.keys(val).map((key, index) => {
        this[key] = val[key];
      });

      this.setTasksParams(this.app_info);
      this.getAppData(this.app_info);

      //console.log("batch tasks initParams::::::", this.app_info);
    },
    setTasksParams(app_info) {
      const me = this;
      const main_series_keyname = app_info.key_name;
      this.radioList = {};
      let stepList = [
        { key_name: main_series_keyname, key_title: app_info.key_title },
      ];

      //console.log("batch tasks initstepList::::::", stepList);

      this.customParams = app_info.custom_parameters || [];
      this.formData = app_info.custom_parameters.map(function (item) {
        if (item.param_type === "mult-select") {
          return {
            param_key: item.param_key,
            param_value: [item.param_value[0]],
          };
        } else if (item.param_type === "single-select") {
          return {
            param_key: item.param_key,
            param_value: item.param_value[0],
          };
        } else {
          return {
            param_key: item.param_key,
            param_value: item.param_value,
          };
        }
      });

      this.baseInfo = {
        task_name: app_info.task_name || "",
      };

      if (app_info.subtypes && app_info.subtypes.length > 0) {
        app_info.subtypes.map((item) => {
          stepList.push({
            key_name: item.key_name,
            key_title: item.key_title,
          });
          me.radioList[item.key_name] = {};
        });
      }
      me.stepList = stepList;
      me.radioList[main_series_keyname] = {};

      //console.log("setTasksParams stepList", stepList);
    },
    async getAppData(app_info) {
      //获取支持当前分析应用的病人
      const me = this;
      //if (!this.appData.length) return;
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      // let params = {
      //   request: "grouplist",
      //   userid: this.$store.state.userid,
      //   projectid: this.$route.query.id,
      //   search_checkobject: "file", //取到序列一级的数据
      //   search_modality: [app_info.modality],
      //   search_series: app_info.series,
      //   search_body_part_examined: app_info.body_part_examined, //取到只支持该appid的序列
      // };

      let params = {
        project_id: this.$route.query.id,
        app_id: app_info.app_id,
      };

      try {
        let res = await projectAppFiles(params);

        if (res.status === 200) {
          //res.groups.map((group, g) => {
          //group.collapse = false;

          // if (group.subjects.length > 0) {
          //   group.subjects.map((subject, s) => {
          //     let subjectid = subject.id;
          //     if (subject.files.length > 0) {
          //       subject.files.map((files, f) => {
          //         const key_name = files.key_name;
          //         if(files.data.length>0){
          //         let defaultselect = files.data[0].id;
          //           me.radioList[key_name][subjectid] = defaultselect;
          //         }
          //       });
          //     }
          //   });
          // }
          //});
          res.groups.map((group, g) => {
            group.collapse = true;
          });
          //console.log("init radioList:::::", me.radioList);
          me.tableData = res.groups;
        } else {
          me.tableData = [];
        }
        ////console.log(me.tableData);
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    tableHeaderStyle({ row, column, rowIndex, columnIndex }) {
      const me = this;
      if (me.step == 0 || !me.stepList[me.step - 1]) return "headerStyle";

      let main_step = me.stepList[0].key_name;
      let current_step = me.stepList[me.step - 1].key_name || "";
      //console.log("tableHeaderStyle", main_step, current_step);
      if (main_step != current_step) {
        return "headerStyle";
      }
    },
    radioChange(file_id, current_step, row) {
      //单选 radio 按钮事件
      const me = this;
      let main_step = this.stepList[0].key_name;
      let subjectid = row.id;
      let groupid = row.group_id;
      if (
        current_step == main_step &&
        this.$refs.multipleTable &&
        this.$refs.multipleTable.length > 0
      ) {
        this.$refs.multipleTable.forEach(function (table) {
          let key_name = table.$attrs.step_name;
          //console.log("key_name", key_name, main_step);
          if (key_name == main_step) {
            if (groupid == table.$attrs.group_id) table.toggleRowSelection(row);
            let mainFile = { [subjectid]: file_id };

            me.radioList[main_step] = Object.assign(
              me.radioList[main_step],
              mainFile
            );
          } else {
            table.data.forEach((d) => {
              if (d.id == subjectid && d.files[key_name].length) {
                table.toggleRowSelection(d);
                let firstFile = { [subjectid]: d.files[key_name][0].id };

                me.radioList[key_name] = Object.assign(
                  me.radioList[key_name],
                  firstFile
                );
              }
            });
          }
        });
      }

      this.radioList = Object.assign({}, this.radioList);
      // console.log(
      //   "单选 radio 按钮事件 radioChange",
      //   current_step,
      //   row,
      //   me.radioList
      // );
    },
    handleSelectChange(selection, row) {
      //单选操作事件,单独选某一病人
      const me = this;
      let current_step = me.stepList[me.step - 1].key_name;
      let main_step = me.stepList[0].key_name;

      let subjectid = row.id;
      let groupid = row.group_id;
      if (selection.length) {
        //选中
        me.radioList[current_step][subjectid] = row.files[current_step][0].id;
      } else {
        //取消选择

        me.stepList.forEach((r) => {
          delete me.radioList[r.key_name][subjectid];
        });
      }

      //其它序列按照主序列选中
      if (this.$refs.multipleTable && this.$refs.multipleTable.length > 0) {
        this.$refs.multipleTable.forEach(function (table) {
          //console.log("table", table);

          let key_name = table.$attrs.step_name;
          let group_id = table.$attrs.group_id;
          if (key_name != main_step && group_id == groupid) {
            if (selection.length) {
              table.data.forEach((d) => {
                table.toggleRowSelection(d, true);
                if (d.id == subjectid && d.files[key_name].length) {
                  let firstFile = { [subjectid]: d.files[key_name][0].id };

                  me.radioList[key_name] = Object.assign(
                    me.radioList[key_name],
                    firstFile
                  );
                }
              });
            } else {
              table.data.forEach((d) => {
                table.toggleRowSelection(d, false);
              });
            }
          }
        });
      }

      console.log("单独选某一病人 handleSelectChange", me.radioList);
    },
    selectAllChange(selection) {
      const me = this;
      let main_step = me.stepList[0].key_name;
      if (selection.length) {
        selection.forEach((s) => {
          this.handleSelectChange([s], s);
        });
      } else {
        //取消全选，selection为空，从现有的表格中查找已选择的行
        console.log("全选取消 radioList", selection);
        me.radioList = {};
        me.stepList.forEach((s) => {
          me.radioList[s.key_name] = {};
        });
        let selectitems = {};
        if (this.$refs.multipleTable && this.$refs.multipleTable.length > 0) {
          this.$refs.multipleTable.forEach(function (table) {
            let key_name = table.$attrs.step_name;

            if (table.selection.length > 0 && key_name == main_step) {
              table.selection.forEach(function (item) {
                selectitems = Object.assign({}, selectitems, {
                  [item.id]: item.files[key_name][0].id,
                });
              });

              me.radioList[main_step] = Object.assign(
                me.radioList[main_step],
                selectitems
              );
            }
          });
        }
      }
    },
    handleSelectAllChange(groupid) {
      //全选操作事件，全选一个分组

      console.log("全选 radioList", groupid);
      const me = this;
      //let current_step = me.stepList[me.step - 1];
      let main_step = me.stepList[0].key_name;

      if (this.$refs.multipleTable && this.$refs.multipleTable.length > 0) {
        this.$refs.multipleTable.forEach(function (table) {
          let key_name = table.$attrs.step_name;
          let group_id = table.$attrs.group_id;
          //选中
          if (
            table.selection.length > 0 &&
            key_name == main_step &&
            groupid == group_id
          ) {
            table.data.forEach((s) => {
              me.handleSelectChange([s], s);
            });
          }
          //取消选中
          if (
            !table.selection.length &&
            key_name == main_step &&
            groupid == group_id
          ) {
            table.data.forEach((s) => {
              me.handleSelectChange([], s);
            });
          }
        });
      }
    },
    selectable(row, index) {
      //console.log("selectable", this.step, row, index);

      return this.step == 1;
    },

    getSelections() {
      //获取选中的subjectid
      let selections = {};

      this.stepList.forEach((item) => {
        selections[item.key_name] = [];
      });
      if (this.$refs.multipleTable && this.$refs.multipleTable.length > 0) {
        this.$refs.multipleTable.forEach(function (table) {
          if (table.selection.length > 0) {
            //console.log("select tables", table.$attrs.step_name);
            let key_name = table.$attrs.step_name;
            table.selection.forEach(function (item) {
              selections[key_name].push(item.id); //push subject id
            });
          }
        });
      }
      return selections;
    },
    selectFiles() {
      const me = this;
      let selectfiles = [];
      let subjectSelection = this.getSelections();
      let radiolist = this.radioList;

      console.log(this.stepList, subjectSelection);

      this.stepList.forEach((item) => {
        if (subjectSelection[item.key_name].length > 0) {
          //subject id
          subjectSelection[item.key_name].forEach((subjectid) => {
            selectfiles.push(radiolist[item.key_name][subjectid]);
          });
        }
      });
      //console.log("selectfiles", selectfiles);
      return selectfiles;
    },

    goPrevStep() {
      if (this.step <= 0) {
        this.step = 0;
      } else {
        this.step -= 1;
      }
    },
    goNextStep() {
      this.step += 1;
      //console.log("step", this.step, this.stepList.length);
    },
    //提交任务
    async submitTask(postParams) {
      let _self = this;
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      try {
        let res = await batchCreateTaskRequest(postParams);
        if (res.status === 200) {
          _self.$helper.successNotify(
            res.message,
            function () {
              // _self.$router.push("/mri-analysis");
            },
            1000
          );
          _self.goNextStep();
        } else {
          _self.$notify.error({
            message: res.message,
            type: "warning",
            title: _self.$t("project.msg_error"),
          });
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        _self.$helper.errNotify(error.message);
      }
    },
    submitParam() {
      let _self = this;
      let selectFileIDs = _self.selectFiles();
      if (_self.baseInfo.task_name == "") {
        _self.$helper.warningMessage(_self.$t("validate.task_name"));
        return false;
      }

      let custom_params = this.formData.filter(function (item) {
        return (
          (item.param_key !== "multi-select_apram" &&
            item.param_value.toString() != "") ||
          (item.param_key === "multi-select_apram" &&
            item.param_value.length > 0)
        );
      });
      if (custom_params.length !== this.formData.length) {
        _self.$helper.warningMessage(_self.$t("service.notice7"));
        return false;
      }

      if (!selectFileIDs.length) {
        _self.$helper.warningMessage(_self.$t("service.notice9"));
        return false;
      }

      let postData = {
        file_ids: selectFileIDs,
        appid: this.appid,
        project_id: this.project_id,
        ...this.baseInfo,
        custom_params: this.formData,
        subjects: this.radioList,
        main_series: this.stepList[0].key_name,
      };

      //console.log("baseInfo:::", _self.baseInfo);
      //console.log("postData:::", postData);

      _self.submitTask(postData);
    },
    closePopover() {
      this.step = 0;
      this.typeSelection = [];
      this.appData = [];

      this.$emit("closePorjectBatchTasksPopover");
    },

    formatGender: function (row) {
      return row.subject_gender === "0"
        ? this.$t("male")
        : row.subject_gender === "1"
        ? this.$t("female")
        : "";
    },
    filterStatus: function (row) {
      const me = this;
      let status = "";
      if (row.files.length) {
        status = me.formatStatus(row.files[0]["task_state"]);
      }

      return status;
    },

    formatStatus: function (status) {
      const _that = this;
      switch (status) {
        case "0":
          return _that.$t("task_status.created");
        case "1":
          return _that.$t("task_status.wait");
        case "2":
          return _that.$t("task_status.processing");
        case "3":
          return _that.$t("task_status.completed");
        case "4":
          return _that.$t("task_status.failed");
        case "5":
          return _that.$t("task_status.timeout");
        case "6":
          return _that.$t("task_status.license_expired");
        case "7":
          return _that.$t("task_status.license_not_found");
        case "8":
          return _that.$t("task_status.license_error");
        case "12":
          return _that.$t("task_status.invalid");
        default:
          return _that.$t("task_status.unsubmit");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.params-popover {
  .popover-head {
    position: relative;
    border-bottom: none;
    .close-btn {
      position: absolute;
      top: 10px;
      right: 20px;
      font-size: 20px;
      cursor: pointer;
    }
  }
  .popover-content {
    width: 1200px;
    min-height: 80vh;
    margin: 45px auto 0;
  }
  .steps-wrapper {
    // position: relative;
    display: flex;
    justify-content: center;
    margin: 0 0 10px;
    border-bottom: 1px solid #dee2e6;
    height: 40px;
    .step-item {
      margin: 0 10px;
      padding-bottom: 12px;

      text-align: center;
      line-height: 20px;
      font-size: 14px;
      overflow: hidden;
      &.active {
        color: #027bff;
        border-bottom: 2px solid #027bff;
        .circle {
          background: #027bff;
        }
      }
    }
    .step-one {
      left: 180px;
    }
    .step-two {
      left: 360px;
    }
    .step-three {
      left: 540px;
    }
    .circle {
      display: inline-block;
      margin-right: 6px;
      width: 20px;
      height: 20px;
      text-align: center;
      border-radius: 50%;
      background: #707070;
      color: #fff;
    }
  }
  .popover-body {
    padding: 0 90px;
    border-bottom: none;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(95vh - 190px);
    .body-title {
      font-size: 14px;
    }
    .tab-content {
      max-height: 300px;
      overflow-y: auto;
    }
    .el-tabs__nav-wrap {
      max-width: 220px;
    }
    .el-tabs__item {
      word-wrap: break-word;
      word-break: break-all;
      overflow-wrap: break-word;
      padding: 10px 20px !important;
      line-height: 20px;
      height: auto;
      white-space: pre-wrap !important;
    }
    .el-tabs__item {
      min-width: 160px;
    }
    .el-checkbox__label {
      color: #606266;
    }
    .select-item {
      display: flex;
      align-items: center;
      height: 40px;
    }
  }

  // padding-bottom: 20px;
  .section {
    padding: 20px 70px;

    &:last-child {
      border-bottom: none;
      padding: 10px 0;
    }
    .section-title {
      margin-bottom: 10px;
    }
  }
  .seccess-content {
    text-align: center;
    padding: 40px 50px 20px;
    .success-icon {
      font-size: 40px;
      color: #26a745;
    }
    .success-text {
      font-size: 14px;
    }
  }

  .select-step {
    .el-table td {
      padding: 6px 0 !important;
    }
  }

  .button-wrapper {
    padding: 20px 90px;
    height: 70px;
  }

  .base-info {
    // margin-bottom: 20px;
    .info-item {
      display: flex;
    }
    .task-name {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      width: 100%;
      .input-box {
        flex: 1;
      }
    }
    .system-age {
      display: flex;
      align-items: center;
      margin-right: 15px;
      width: 50%;
      .input-box {
        width: 100px;
        margin-right: 10px;
      }
      .select-box {
        width: 80px;
      }
    }
    .system-gender {
      display: flex;
      align-items: center;
      width: calc(~"50% - 20px");
    }
    .info-lable {
      display: block;
      margin-right: 10px;
      width: 73px;
      text-align: right;
    }
  }
  .series-content {
    padding: 20px 50px;
  }
  .el-select {
    width: 100%;
  }
  label {
    margin-bottom: 0;
  }
}

/deep/.headerStyle .el-checkbox {
  display: none;
}
</style>
