<template>
  <transition name="el-zoom-in-center">
    <div class="brain-popover" v-if="showPopover">
      <div class="popover-mask"></div>
      <div class="popover-content">
        <div class="popover-head">
          <span class="popover-title">{{ PopoverTitle }}</span>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click.stop="closePopover"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="popover-body">
          <div class="project-card" v-for="group in tableData" :key="group.id">
            <div class="px-3 py-3 card-name">
              <span class="cursor" @click.stop="collapseGroup(group.id)"
                >{{ group.name }} [ {{ group.data.length }} ]</span
              >
              <span class="px-2 cursor" @click.stop="collapseGroup(group.id)">
                <i
                  :class="
                    group.collapse ? ' fa fa-caret-down' : ' fa fa-caret-right'
                  "
                ></i>
              </span>
            </div>

            <el-table
              ref="multipleTable"
              :data="group.data"
              tooltip-effect="dark"
              style="width: 100%"
              v-show="group.collapse"
              :row-key="group.id"
            >
              <el-table-column type="selection" :reserve-selection="true">
              </el-table-column>
              <el-table-column prop="subject_name" :label="$t('_data.p_name')">
              </el-table-column>
              <el-table-column
                prop="subject_gender"
                :label="$t('gender')"
                :formatter="formatGender"
              >
              </el-table-column>
              <el-table-column prop="subject_age" :label="$t('age')">
              </el-table-column>
              <el-table-column
                prop="task_name"
                :label="$t('analyse.task_name')"
              >
              </el-table-column>
              <el-table-column
                prop="date_created"
                :label="$t('project.c_time')"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="popover-footer clearfix">
          <div
            class="float-left pl-3 ml-3"
            v-if="DownloadParams.data_type == 'result'"
          >
            <span
              ><i class="el-icon-warning" type="warning"></i>
              {{ $t("project.text_batchdown_tips") }}</span
            >
          </div>
          <div class="float-right">
            <button
              type="button"
              class="btn btn-grey btn-sm ml10"
              @click.stop="closePopover"
            >
              {{ $t("close") }}
            </button>
            <button
              type="button"
              class="btn btn-primary btn-sm ml10"
              @click.stop="submitBatchDownload('subjectForm')"
            >
              {{ $t("confirm") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { eventHub } from "@/eventHub.js";
import { projectCreatePackRequest } from "../../api/api";

import { MessageBox, Loading } from "element-ui";

export default {
  props: {
    tableData: {
      default: function () {
        return {};
      },
    },
    showPopover: {
      type: Boolean,
      default: false,
    },
    PopoverTitle: {
      type: String,
      default: "",
    },
    DownloadParams: {
      type: Object,
      default: () => {
        return {
          project_id: null,
          app_id: null,
          data_type: null,
        };
      },
    },
  },
  data() {
    return {
      multipleSelection: [],
    };
  },
  computed: {},
  created() {
    //console.log(this.DownloadParams);
    this.getTaskData();
  },
  methods: {
    collapseGroup(groupid) {
      // let temptable = this.tableData;
      // let index = temptable.filter((t) => t.id == groupid);
      // console.log(index);
      // index[0][collapse] = false;
      // this.tableData = temptable;

      this.tableData.map((t, i) => {
        if (t.id == groupid) {
          t.collapse = !t.collapse;
        }
      });
    },
    clearSelections() {
      //取消所有selected
      this.$refs.multipleTable.forEach(function (table) {
        table.clearSelection();
      });
    },
    getSelections() {
      let selections = [];
      if (this.$refs.multipleTable && this.$refs.multipleTable.length > 0) {
        this.$refs.multipleTable.forEach(function (table) {
          if (table.selection.length > 0) {
            table.selection.forEach(function (item) {
              selections.push(item.id);
            });
          }
        });
      }
      return selections;
    },
    //提交批量下载任务
    async submitBatchDownload(formName) {
      let selections = this.getSelections();
      //this.clearSelections();

      if (!selections.length) {
        this.$message(this.$t("notice.select_data"));
        return;
      }

      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      let params = Object.assign({}, this.DownloadParams, {
        task_ids: selections,
      });

      try {
        let res = await projectCreatePackRequest(params);
        console.log(res);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          eventHub.$emit("getProcessList");
        } else {
          this.$helper.errNotify(res.message);
        }
        this.closePopover();
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    //显示该项目下的分析列表
    async getTaskData(row) {
      if (!this.showPopover) return;
    },

    closePopover() {
      this.$emit("closePorjectTaskPopover");
    },

    formatGender: function (row) {
      return row.subject_gender === "0"
        ? this.$t("male")
        : row.subject_gender === "1"
        ? this.$t("female")
        : "";
    },
  },
};
</script>

<style lang="less" scoped>
.brain-popover .popover-content {
  width: 80%;
  margin: 50px auto 0;
}

.popover-body {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(95vh - 190px);
}

.project-card {
  margin-bottom: 20px;
  background: #eee;
  transition: 0.3s ease-in-out;

  .fa-caret-down {
    transition: 0.3s ease-in-out;
  }

  &.group-collapse {
    max-height: 81px;
    overflow: hidden;

    .fa-caret-down {
      transform: rotate(-90deg);
    }
  }

  .el-checkbox {
    margin-bottom: 0 !important;
  }

  .el-table .cell {
    overflow: visible;
  }

  .el-table {
    overflow: visible;
  }

  .el-table__body-wrapper {
    overflow: visible !important;
  }
}

.card-name {
  line-height: 24px;
}
</style>
