<template>
  <div class="project">
    <div class="main-content project-data-content">
      <div class="clearfix border-bottom mb-3">
        <div class="mb-3 pb-3">
          <!--导航面包屑-->
          <ol class="breadcrumb project-bread">
            <li class="breadcrumb-item">
              <a class="text-dark" @click="$router.back()">
                <strong>
                  <i class="fa fa-chevron-left mr5"></i>
                  {{ $t("back") }}
                </strong>
              </a>
            </li>
            <li class="breadcrumb-item text-grey" aria-current="page">
              {{ $t("nav.project_manage") }}
            </li>
            <li class="breadcrumb-item text-dark" aria-current="page">
              {{ $t("project.detail") }}
            </li>
          </ol>
        </div>
        <!--tab 切换-->
        <div class="tab">
          <span
            class="tab-link"
            :class="tabactive === 'data' ? 'tab-link-active' : ''"
            @click="tabChange('data')"
            >{{ $t("_data.manage") }}</span
          >
          <span
            v-if="user_rights.analysis != 'disable'"
            class="tab-link"
            :class="tabactive === 'application' ? 'tab-link-active' : ''"
            @click="tabChange('application')"
            >{{ $t("project.application") }}</span
          >
        </div>
      </div>
      <div class="project-content" v-if="tabactive === 'data'">
        <div class="search-wrapper">
          <!--搜索-->
          <span class="search-btn" @click.stop="getProjectGroup">{{
            $t("search")
          }}</span>
          <el-form
            :inline="true"
            :model="searchForm"
            class="search-form"
            size="small"
          >
            <el-form-item label=" ">
              <el-input
                v-model="searchForm.search"
                clearable
                :placeholder="$t('analyse.fuzzy_search')"
              ></el-input>
            </el-form-item>
          </el-form>

          <!--更换分组-->
          <button
            v-if="isMember"
            type="button"
            class="btn btn-info btn-sm ml-3"
            @click.stop="showChangeGroup"
          >
            <i class="fa fa-people-carry mr5"></i>
            {{ $t("project.ch_group") }}
          </button>

          <!--创建分组按钮-->
          <button
            v-if="isMember"
            type="button"
            class="btn btn-danger btn-sm ml10"
            @click="showCreateGroup"
          >
            {{ $t("project.c_group") }}
          </button>

          <!-- <div class="flexible search-left">
            <div class="project-name mr5">{{ baseInfo.name }}</div>
            <div class="owner-info">
              <span class="mr5">{{ $t("project.owner") }}:</span>
              <span class="mr5">{{ creator.realname }}</span>
              <span class="mr5">{{ creator.office }}</span>
              <span class="mr5">{{ creator.department }}</span>
              <span>{{ creator.title }}</span>
            </div>
          </div> -->
        </div>
        <!--转档进度-->
        <div class="data-process" v-if="processList.length > 0">
          <el-carousel
            height="20px"
            direction="vertical"
            arrow="never"
            indicator-position="none"
          >
            <el-carousel-item v-for="item in processList" :key="item.id">
              <div
                class="data-item clearfix"
                :class="processClass(item.status)"
              >
                <span class="fl mr5">{{ item.file_name }}</span>
                <span class="fl mr10">{{ item.status | formatStatus }}</span>
                <span v-if="item.status === '2'" class="fl mr10"
                  >{{ $t("package_status.failure_reason") }}：{{
                    item.error_status | formatMessage
                  }}</span
                >
                <span class="fl">{{ item.date_created }}</span>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>

        <div class="project-tabel-content">
          <div
            class="project-card"
            v-for="group in projectGroup"
            :key="group.id"
            :class="group.collapse ? 'group-collapse' : 'group-expand'"
          >
            <div class="clearfix pt-2 pr-2 pb-2">
              <div
                class="fl px-3 card-name cursor"
                @click.stop="collapseGroup(group.id)"
              >
                <span>{{ group.name }} 【{{ group.subjects.total }}】</span>
                <span class="px-2 cursor">
                  <i class="fa fa-caret-down"></i>
                </span>
              </div>
              <div class="fr">
                <div class="dropdown d-inline-block dropleft">
                  <div class="cursor" @click.stop="showGroupMenu(group.id)">
                    <i class="fa fa-ellipsis-v text-muted px-2"></i>
                  </div>
                  <div
                    class="dropdown-menu shadow-lg"
                    :class="{ show: group.id === groupMenuId }"
                  >
                    <div class="dropdown-header">
                      {{ $t("operate_menu") }}
                    </div>
                    <div
                      class="dropdown-item cursor"
                      @click="showEditGroup(group)"
                    >
                      {{ $t("project.e_group") }}
                    </div>
                    <div class="dropdown-divider"></div>
                    <div
                      class="dropdown-item cursor text-danger"
                      @click="handleDeleteGroup(group.id)"
                    >
                      {{ $t("delete") }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="fr mr10">
                <router-link
                  v-if="isMember"
                  class="btn btn-warning btn-xs"
                  :to="{
                    name: 'addSubject',
                    query: { groupid: group.id, projectid: group.project_id },
                  }"
                >
                  <i class="fa fa-plus-circle"></i> {{ $t("project.u_data") }}
                </router-link>
              </div>
            </div>
            <el-table
              v-if="group.subjects.data"
              ref="multipleTable"
              tooltip-effect="dark"
              style="width: 100%"
              size="small"
              :data="group.subjects.data"
              :row-key="group.id"
            >
              <el-table-column
                type="selection"
                :reserve-selection="true"
                width="55"
              ></el-table-column>
              <el-table-column
                prop="subject_name"
                :label="$t('project.p_name')"
              >
                <template v-slot="scope">
                  <router-link
                    :to="{
                      name: 'subjectDetail',
                      query: { id: scope.row.id },
                    }"
                    >{{ scope.row.subject_name }}</router-link
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="subject_gender"
                :formatter="formatGender"
                :label="$t('gender')"
              ></el-table-column>
              <el-table-column prop="subject_age" :label="$t('age')" width="80">
                <template v-slot="scope">
                  <span>
                    {{ scope.row.subject_age }}
                    {{ $t($helper.formatAgeUnit(scope.row.subject_age_unit)) }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="study_date"
                :label="$t('project.s_date')"
              ></el-table-column>
              <el-table-column
                prop="date_update"
                :label="$t('project.up_time')"
              ></el-table-column>
              <el-table-column :label="$t('analyse.body_part')">
                <template v-slot="scope">
                  <span
                    class="mr5"
                    v-for="part in scope.row.body_part_examined"
                    :key="part"
                    >{{ part }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column :label="$t('project.modality')">
                <template v-slot="scope">
                  <span
                    class="mr5"
                    v-for="modal in scope.row.modality"
                    :key="modal"
                    >{{ modal }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column prop="tags" :label="$t('project.tags')">
                <template v-slot="scope">
                  <span
                    class="mr5"
                    v-for="tag in scope.row.tags"
                    :key="tag.id"
                    >{{ tag.name }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column :label="$t('operate')" align="right">
                <template v-slot="scope">
                  <el-dropdown>
                    <span class="el-dropdown-link cursor">
                      <i class="fa fa-bars mr-1"></i>
                      <i class="fa fa-angle-down"></i>
                    </span>

                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item disabled>{{
                        $t("operate_menu")
                      }}</el-dropdown-item>

                      <el-dropdown-item
                        divided
                        class="dropdown-item cursor"
                        @click.native="modifyInfo(scope.row)"
                      >
                        <i class="fa fa-edit"></i> {{ $t("project.e_info") }}
                      </el-dropdown-item>
                      <el-dropdown-item
                        class="dropdown-item cursor"
                        @click.native="modalDownload(scope.row, 'nifti')"
                      >
                        <i class="fa fa-download"></i>
                        {{ $t("_data.d_nif") }}
                      </el-dropdown-item>
                      <el-dropdown-item
                        class="dropdown-item cursor"
                        @click.native="modalDownload(scope.row, 'dicom')"
                      >
                        <i class="fa fa-download"></i>
                        {{ $t("_data.d_dic") }}
                      </el-dropdown-item>
                      <el-dropdown-item
                        class="dropdown-item cursor"
                        @click.native="showTagsModel(scope.row)"
                      >
                        <i class="fa fa-tags"></i> {{ $t("_data.add_tag") }}
                      </el-dropdown-item>

                      <el-dropdown-item
                        class="dropdown-item cursor"
                        @click.native="handleDeleteSubject(scope.row)"
                      >
                        {{ $t("delete") }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <!-- 
                  <div class="dropdown d-inline-block dropleft">
                    <div
                      class="cursor"
                      @click.stop="showListMenu(scope.row.id)"
                    >
                      <i class="fa fa-bars"> </i>
                      <i class="fa fa-angle-down"></i>
                    </div>
                    <div
                      class="dropdown-menu shadow-lg"
                      :class="{ show: scope.row.id === listMenuId }"
                    >
                      <div class="dropdown-header">
                        {{ $t("operate_menu") }}
                      </div>
                      <div
                        class="dropdown-item cursor"
                        @click="modifyInfo(scope.row)"
                      >
                        <i class="fa fa-edit"></i> {{ $t("project.e_info") }}
                      </div>
                      <div
                        class="dropdown-item cursor"
                        @click="modalDownload(scope.row, 'nifti')"
                      >
                        <i class="fa fa-download"></i>
                        {{ $t("_data.d_nif") }}
                      </div>
                      <div
                        class="dropdown-item cursor"
                        @click="modalDownload(scope.row, 'dicom')"
                      >
                        <i class="fa fa-download"></i>
                        {{ $t("_data.d_dic") }}
                      </div>
                      <div class="dropdown-divider"></div>
                      <div
                        class="dropdown-item cursor"
                        @click="showTagsModel(scope.row)"
                      >
                        <i class="fa fa-tags"></i> {{ $t("_data.add_tag") }}
                      </div>
                      <div class="dropdown-divider"></div>
                      <div
                        class="dropdown-item cursor text-danger"
                        @click="handleDeleteSubject(scope.row)"
                      >
                        {{ $t("delete") }}
                      </div>
                    </div>
                  </div> -->
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="clearfix mt-3 mb-3">
          <div class="float-right">
            <button
              type="button"
              class="btn btn-primary btn-sm"
              @click.stop="batchDownloadData"
            >
              <i class="fa fa-download mr5"></i>
              {{ $t("_data.batch_download") }}
            </button>
          </div>
        </div>
      </div>

      <!-- 服务列表 -->
      <div class="service-content" v-else-if="tabactive === 'application'">
        <el-table :data="serviceData" style="width: 100%">
          <el-table-column
            prop="app_name"
            :label="$t('authority.analysis')"
            align="center"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="modality"
            :label="$t('project.modality')"
            align="center"
            width="80"
          >
          </el-table-column>
          <el-table-column
            prop="series"
            :label="$t('project.series')"
            align="center"
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="body_part_examined"
            align="center"
            :label="$t('analyse.body_part')"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="completion_ratio"
            align="center"
            :label="$t('project.completion_ratio')"
            width="200"
          >
          </el-table-column>

          <el-table-column :label="$t('project.batch_operations')">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="setBatchTasks(scope.row)"
                >{{ $t("project.batch_analyse") }}</el-button
              >

              <el-button
                v-if="
                  scope.row.statistic == true &&
                  parseInt(scope.row.completion) > 0
                "
                @click="getTaskData(scope.row, 'statistic')"
                type="text"
                size="small"
                >{{ $t("project.text_statistic") }}</el-button
              >
              <el-button
                v-if="
                  scope.row.result == true && parseInt(scope.row.completion) > 0
                "
                @click="getTaskData(scope.row, 'result')"
                type="text"
                size="small"
                >{{ $t("project.text_result") }}</el-button
              >
            </template>
          </el-table-column>

          <el-table-column align="center" width="100">
            <template slot-scope="scope">
              <el-button
                v-show="parseInt(scope.row.submitted) > 0"
                @click="getTaskList(scope.row)"
                type="text"
                size="small"
                >{{ $t("analyse.result") }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="info-side">
      <div class="side-header">
        <span class="mr5">{{ $t("project.p_info") }}</span>
        <span
          class="edit-btn cursor"
          v-if="
            user.realname === creator.realname ||
            user.username === creator.username
          "
          @click.stop="showEditProjectModel"
        >
          [{{ $t("_data.modify") }}]
        </span>
      </div>
      <div class="side-content">
        <div class="info-item clearfix">
          <span class="fl">{{ $t("project.name") }}:</span>
          <span class="fr">{{ baseInfo.name }}</span>
        </div>
        <div class="info-item clearfix">
          <span class="fl">{{ $t("project.owner") }}:</span>
          <span class="fr ml5 modality-span">{{ creator.username }}</span>
        </div>
        <div class="info-item clearfix">
          <span class="fl">{{ $t("project.g_num") }}:</span>
          <span class="fr">{{ baseInfo.group_number }}</span>
        </div>
        <div class="info-item clearfix">
          <span class="fl">{{ $t("project.p_num") }}:</span>
          <span class="fr">{{ baseInfo.subject_number }}</span>
        </div>
        <div class="info-item">
          <p class="item-title">{{ $t("project.p_des") }}:</p>
          <div class="item-scroll">{{ baseInfo.description }}</div>
        </div>
        <div class="info-item">
          <p class="item-title clearfix">
            <span class="fl">{{ $t("project.p_member") }}:</span>
            <span
              v-if="
                members.length > 0 &&
                (user.realname === creator.realname ||
                  user.username === creator.username)
              "
              class="cursor fr"
              @click="showMinusMember"
              ><i class="el-icon-minus"></i>
            </span>
            <span
              v-if="
                user.realname === creator.realname ||
                user.username === creator.username
              "
              class="cursor fr mr5"
              @click="getNotSharedMembers"
              ><i class="el-icon-plus"></i>
            </span>
          </p>
          <div class="item-scroll">
            <p class="member" v-for="item in members" :key="item.id">
              {{ item.realname }}
            </p>
          </div>
        </div>
      </div>
      <div class="analyse-wrapper">
        <!-- <div class="section-title">{{ $t("project.p_res") }}</div>
        <div class="analyse-list">
          <div class="mt-2">
            <router-link to="/" class="analyse-link">MRI分析</router-link>
          </div>
          <div class="mt-2">
            <router-link to="/" class="analyse-link">MRI分析</router-link>
          </div>
          <div class="mt-2">
            <router-link to="/" class="analyse-link">MRI分析</router-link>
          </div>
        </div> -->
      </div>
    </div>

    <!-- 添加/删除成员 -->
    <div class="brain-popover member-popover" v-if="showMemberPopover">
      <div class="popover-mask"></div>
      <div class="popover-content">
        <div class="popover-head">{{ memPopoverTitle }}</div>
        <div class="popover-body">
          <div class="search-line row">
            <div class="col-sm-2"></div>
            <div class="col-sm-8">
              <el-input v-model="memberSearch">
                <el-button slot="append">{{ $t("search") }}</el-button>
              </el-input>
            </div>
          </div>
          <div class="member-content row">
            <div class="member-list">
              <el-checkbox-group v-model="checkedMembers">
                <div
                  v-for="item in popMembers"
                  :key="item.id"
                  class="member-item"
                >
                  <el-checkbox :label="item.id">
                    <span class="mr5">{{ item.realname }}</span>
                    <span class="mr5">{{ item.department }}</span>
                    <span class="mr5">{{ item.email }}</span>
                    <span class="mr5">{{ item.title }}</span>
                    <span>{{ item.telephone }}</span>
                  </el-checkbox>
                </div>
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <div class="popover-footer text-right">
          <el-button
            type="info"
            @click.stop="closeMemberPopover"
            class="mr-3"
            >{{ $t("close") }}</el-button
          >

          <el-popconfirm
            v-if="memPopoverType === 'add'"
            :confirm-button-text="$t('confirm')"
            :cancel-button-text="$t('cancel')"
            icon="el-icon-info"
            icon-color="red"
            :title="$t('notice.add_confirm')"
            @confirm="updateMembers"
          >
            <el-button slot="reference">{{ $t("project.add") }}</el-button>
          </el-popconfirm>

          <el-popconfirm
            v-if="memPopoverType === 'minus'"
            :confirm-button-text="$t('confirm')"
            :cancel-button-text="$t('cancel')"
            icon="el-icon-info"
            icon-color="red"
            :title="$t('notice.p_confirm')"
            @confirm="updateMembers"
          >
            <el-button slot="reference">{{ $t("delete") }}</el-button>
          </el-popconfirm>
        </div>
      </div>
    </div>

    <!-- 创建/编辑分组 -->
    <div class="brain-popover group-popover" v-if="showGroupPopover">
      <div class="popover-mask"></div>
      <div class="popover-content">
        <div class="popover-head">{{ groupPopoverTitle }}</div>
        <div class="popover-body">
          <div class="search-line">
            <el-form
              :model="group"
              :rules="groupRules"
              ref="groupForm"
              label-width="150px"
            >
              <el-form-item
                :label="$t('project.g_name') + '：'"
                prop="groupname"
              >
                <el-input v-model="group.groupname"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="popover-footer text-right">
          <button
            type="button"
            class="btn btn-grey btn-sm ml10"
            @click.stop="closeGroupPopover"
          >
            {{ $t("cancel") }}
          </button>
          <button
            type="button"
            class="btn btn-primary btn-sm ml10"
            @click.stop="submitGroup('groupForm')"
          >
            {{ $t("save") }}
          </button>
        </div>
      </div>
    </div>

    <!-- 添加标签 -->
    <div class="brain-popover tags-popover" v-if="showTagPopover">
      <div class="popover-mask"></div>
      <div class="popover-content">
        <div class="popover-head">{{ $t("project.tags") }}</div>
        <div class="popover-body tag-popover-body">
          <div class="tags-list clearfix">
            <el-checkbox-group v-model="checkedTags">
              <div class="fl tags-item" v-for="tag in tagsList" :key="tag.id">
                <el-checkbox :label="tag.id">
                  <span class="mr5">{{ tag.name }}</span>
                </el-checkbox>
              </div>
            </el-checkbox-group>
          </div>
          <div class="row clearfix">
            <span class="fl tag-name mr10">{{ $t("project.t_name") }}:</span>
            <div class="fl mr10 tag-input">
              <el-input v-model="tagname"></el-input>
            </div>
            <span class="fl cursor add-tag" @click="addTag">{{
              $t("project.a_tag")
            }}</span>
          </div>
        </div>
        <div class="popover-footer text-right">
          <button
            type="button"
            class="btn btn-grey btn-sm ml10"
            @click.stop="closeTagsModel"
          >
            {{ $t("cancel") }}
          </button>
          <button
            type="button"
            class="btn btn-primary btn-sm ml10"
            @click.stop="setTags"
          >
            {{ $t("save") }}
          </button>
        </div>
      </div>
    </div>

    <!-- 更换分组 -->
    <div class="brain-popover member-popover" v-if="showChangeGroupPopover">
      <div class="popover-mask"></div>
      <div class="popover-content">
        <div class="popover-head">{{ $t("project.ch_group") }}</div>
        <div class="popover-body">
          <div class="member-content row">
            <el-radio-group v-model="selectGroup">
              <el-radio
                v-for="item in projectGroup"
                :key="item.id"
                :label="item.id"
                >{{ item.name }}</el-radio
              >
            </el-radio-group>
          </div>
        </div>
        <div class="popover-footer text-right">
          <button
            type="button"
            class="btn btn-grey btn-sm ml10"
            @click.stop="hideChangeGroup"
          >
            {{ $t("cancel") }}
          </button>
          <button
            type="button"
            class="btn btn-primary btn-sm ml10"
            @click.stop="handleChangeGroup"
          >
            {{ $t("save") }}
          </button>
        </div>
      </div>
    </div>

    <!-- 等待下载 -->
    <div class="dialog-wrapper" v-if="dialogVisible">
      <div class="dialog-content">
        <div class="dialog-header">
          <span class="dialog-title">{{ $t("_data.tips") }}</span>
          <button
            type="button"
            class="dialog-headerbtn"
            @click="dialogVisible = false"
          >
            <i class="dialog-close el-icon el-icon-close"></i>
          </button>
        </div>
        <div class="dialog-body">
          <span>{{ $t("_data.packing") }}</span>
        </div>
      </div>
    </div>

    <!-- 修改受试者信息 -->
    <edit-subject
      :patientInfo="edit_subject"
      :showPopover="showEditSubject"
      @closeEditPopover="showEditSubject = false"
      @submitSubject="submitSubject"
    >
    </edit-subject>
    <project-model
      v-if="showProjectModel"
      :modelType="'edit'"
      :project="editProjectInfo"
      @submit-project="submitProject"
      @close-model="showProjectModel = false"
    />

    <!--批量下载-->
    <projectBatchDownload
      :showPopover="showProjectTask"
      :PopoverTitle="taskPopoverTitle"
      :tableData="projectTaskData"
      :DownloadParams="batchDownloadParams"
      @closePorjectTaskPopover="showProjectTask = false"
    />

    <!--查看任务结果 任务列表-->
    <projectTaskList
      :showPopover="showProjectTaskList"
      :Params="taskListParams"
      @closePorjectTaskListPopover="showProjectTaskList = false"
    />

    <!--提交批量分析任务-->
    <batchTasks
      :showPopover="showProjectBatchTasks"
      :Params="batchTasksParams"
      @closePorjectBatchTasksPopover="showProjectBatchTasks = false"
    />

    <el-dialog
      :title="$t('notice.pack_ok')"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
      :close-on-click-modal="isClickclose"
      :close-on-press-escape="isClickclose"
      :lock-scroll="isLockScroll"
    >
      <div class="text-center">
        <a
          class="btn btn-primary"
          target="_blank"
          :href="downloadurl"
          style="width: 120px"
          ><i class="el-icon-download"></i> {{ $t("_data.download") }}</a
        >
      </div>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
  </div>
</template>

<script>
import { eventHub } from "@/eventHub.js";
import {
  projectDataRequest,
  projectGroupRequest,
  projectRequest,
  dataDownload,
  dataRequest,
  tagsRequest,
  projectAppTasksRequest,
  projectTasksRequest,
  downloadPackage,
} from "../../api/api";
// import pagination from "../../components/pager";
// import { paginationMixin } from "../../utils/mixin";
import { MessageBox, Loading } from "element-ui";
import editSubject from "../../components/subject/editSubject";
import projectModel from "../../components/projectModel";
import projectBatchDownload from "../../components/project/batch_download";
import projectTaskList from "../../components/project/task_list";
import batchTasks from "../../components/project/batchTasks";

let _that;
export default {
  components: {
    editSubject,
    projectModel,
    projectBatchDownload,
    projectTaskList,
    batchTasks,
  },
  data() {
    return {
      tabactive: "data", //"data" or "application"
      searchForm: {
        search: "",
      },
      projectGroup: new Array(),
      processList: [],
      noTask: false,
      refresh: false,
      interval: "",
      groupMenuId: "",
      listMenuId: "",
      baseInfo: new Object(),
      creator: new Object(),
      showProjectModel: false,
      editProjectInfo: new Object(),
      memberList: [],
      members: new Array(),
      showMemberPopover: false,
      memPopoverType: "",
      memPopoverTitle: this.$t("project.a_mem"),
      popMembers: new Array(),
      memberSearch: "",
      checkedMembers: new Array(),
      showGroupPopover: false,
      groupPopoverTitle: this.$t("project.c_group"),
      groupid: 0,
      group: {
        groupname: "",
      },
      showTagPopover: false,
      dialogVisible: false,
      showEditSubject: false,
      edit_subject: {},
      tagsList: [],
      checkedTags: [],
      subjectid: "",
      tagname: "",
      showChangeGroupPopover: false,
      selectGroup: "",
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : {},
      user_rights: localStorage.getItem("user_rights")
        ? JSON.parse(localStorage.getItem("user_rights"))
        : {},
      dataUserID: null, //项目创建者的userid
      isMember: false,
      serviceData: [
        {
          submitted: "20",
          completion: "10",
          completion_ratio: "10/20",
          app_name: "---",
          body_part_examined: "上海市普",
          modality: "MR",
          series: "3D",
          batchTask: true,
        },
        {
          submitted: "20",
          completion: "10",
          completion_ratio: "10/20",
          app_name: "王小虎",
          body_part_examined: "上海市普",
          modality: "PT",
          series: "3D",
          batchTask: true,
        },
        {
          submitted: "20",
          completion: "10",
          completion_ratio: "10/20",
          app_name: "王小虎",
          body_part_examined: "上海市普",
          modality: "MR",
          series: "3D",
          batchTask: false,
        },
      ],
      taskPopoverTitle: "", //统计数据，结果数据
      showProjectTask: false, //显示项目的分析应用信息
      projectTaskData: {},
      batchDownloadParams: {},
      showProjectTaskList: false, //显示项目的任务列表
      taskListParams: {},
      showProjectBatchTasks: false, //显示项目的批量提交任务
      batchTasksParams: {},
      centerDialogVisible: false,
      isClickclose: false,
      isLockScroll: false,
      downloadurl: "",
    };
  },
  computed: {
    groupRules() {
      return {
        groupname: [
          {
            required: true,
            message: " ",
            trigger: "change",
          },
        ],
      };
    },
  },
  created() {
    this.getProjectGroup();
    this.getProcessList();
    this.getProjectInfo();
    this.getMembers();
    this.pollData();
    //this.getProjectAppTasks();
  },
  mounted() {
    window.addEventListener("click", this.hideGroupMenu, false);
    window.addEventListener("click", this.hideListMenu, false);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.hideGroupMenu);
    window.removeEventListener("click", this.hideListMenu);
    this.clearTimer();
  },
  methods: {
    clearTimer() {
      clearInterval(this.interval);
      this.interval = null;
    },
    pollData() {
      this.interval = setInterval(() => {
        this.getProcessList();
      }, 15000);
    },
    handleClick(row) {
      console.log(row);
    },
    //显示批量提交任务
    setBatchTasks(app_info) {
      let isInfoRequired =
        !!app_info.system_age_value || !!app_info.system_gender;

      let sub_types = app_info.subtypes.length ? app_info.subtypes : [];

      let stepList = [];
      let subtypesData = [];
      let radioList = [];
      if (sub_types.length > 0) {
        stepList = sub_types.map((item) => {
          return item.key_title || item.key_name;
        });
        subtypesData = sub_types.map((item) => {
          return item.files || [];
        });
      }

      if (subtypesData.length) {
        radioList = subtypesData.map(() => {
          return {
            typeRadio: "",
          };
        });
      }

      this.batchTasksParams = {
        app_info: app_info,
        appid: app_info.app_id,
        customParams: app_info.custom_parameters || [],
        project_id: this.$route.query.id,

        isInfoRequired: isInfoRequired,
      };

      //console.log("batchTasksParams", this.batchTasksParams);
      this.showProjectBatchTasks = true;
    },
    //显示该应用的所有分析任务
    getTaskList(row) {
      this.showProjectTaskList = true;
      this.taskListParams = {
        app_id: row.app_id,
        project_id: this.$route.query.id,
      };
    },
    //显示该项目下的分析列表
    async getTaskData(row, data_type) {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      this.showProjectTask = true;
      this.taskPopoverTitle =
        data_type == "statistic"
          ? this.$t("project.text_statistic")
          : this.$t("project.text_result");

      this.batchDownloadParams = {
        project_id: this.$route.query.id,
        app_id: row.app_id,
        data_type: data_type,
      };

      try {
        let res = await projectTasksRequest(this.batchDownloadParams);
        if (res.status === 200) {
          res.array_result.map((group, i) => {
            group.collapse = true;
          });
          this.projectTaskData = res.array_result;
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },

    //项目中分析应用信息接口 projectAppTasksRequest
    async getProjectAppTasks() {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      let params = {
        project_id: this.$route.query.id,
      };
      try {
        let res = await projectAppTasksRequest(params);
        if (res.status === 200) {
          this.serviceData = res.array_result;
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },

    tabChange(tabname) {
      this.tabactive = tabname;
      if (tabname == "application") this.getProjectAppTasks();
    },
    async getProjectGroup() {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        ...this.searchForm,
        request: "grouplist",
        userid: this.$store.state.userid,
        projectid: this.$route.query.id,
      };
      try {
        let res = await projectGroupRequest(params);
        if (res.status === 200) {
          res.data.forEach(function (group) {
            group.collapse = false;
          });
          this.projectGroup = res.data;
        } else {
          this.projectGroup = [];
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    async getProjectInfo() {
      let params = {
        request: "project",
        userid: this.$store.state.userid,
        projectid: this.$route.query.id,
      };
      try {
        let res = await projectDataRequest(params);
        if (res.status === 200) {
          this.baseInfo = res.data ? res.data : {};
          this.creator = res.data.creator ? res.data.creator : {};
          this.dataUserID = res.data.user_id;
        }
      } catch (error) {
        console.log(error.message);
        this.$helper.errNotify(error.message);
      }
    },
    //获取顶部数据处理情况
    async getProcessList() {
      let params = {
        request: "upload_state",
        userid: this.$store.state.userid,
        projectid: this.$route.query.id,
      };

      try {
        let res = await projectDataRequest(params);
        if (res.status === 200) {
          let resData = res.data;
          this.refresh = this.processList.toString() !== resData.toString();
          this.noTask = resData.length === 0;
          this.processList = resData.length > 0 ? resData : [];
        } else {
          this.processList = [];
          this.clearTimer();
        }
        //if (!this.processList.length) clearInterval(this.interval);
      } catch (error) {
        this.$helper.errNotify(error.message);
        this.clearTimer();
      }
    },
    processClass(status) {
      switch (status) {
        case "0":
          return "item-primary";
        case "1":
          return "item-success";
        case "2":
          return "item-danger";
        default:
          return "";
      }
    },
    showEditProjectModel() {
      this.showProjectModel = true;
      this.editProjectInfo = {
        id: this.baseInfo.id || "",
        name: this.baseInfo.name || "",
        operator: this.creator.username || "",
        description: this.baseInfo.description || "",
      };
    },
    async submitProject(project) {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      let params = {
        request: "update",
        userid: this.$store.state.userid,
        ...project,
      };
      try {
        let res = await projectRequest(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          this.getProjectGroup();
          this.getProjectInfo();
          this.showProjectModel = false;
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    async getMembers() {
      let params = {
        request: "project_users",
        userid: this.$store.state.userid,
        projectid: this.$route.query.id,
      };
      try {
        let res = await projectDataRequest(params);
        if (res.status === 200 && res.data.length) {
          this.members = res.data; //.length > 0 ? res.data : [];
        }
      } catch (error) {
        console.log(error.message);
        this.$helper.errNotify(error.message);
      }
    },
    async getNotSharedMembers() {
      this.memberSearch = "";
      let params = {
        request: "members",
        userid: this.$store.state.userid,
        projectid: this.$route.query.id,
      };
      try {
        let res = await projectDataRequest(params);
        if (res.status === 200) {
          if (res.data.length > 0) {
            //this.popMembers = res.data;
            this.memberList = res.data;
            this.memPopoverTitle = this.$t("project.a_mem");
            this.memPopoverType = "add";
            this.showMemberPopover = true;
          } else {
            this.$helper.warningMessage(res.message);
          }
        } else {
          this.$helper.warningMessage(res.message);
        }
      } catch (error) {
        console.log(error.message);
        this.$helper.errNotify(error.message);
      }
    },
    showGroupMenu(groupId) {
      this.groupMenuId = groupId;
    },
    hideGroupMenu() {
      this.groupMenuId = "";
    },
    showListMenu(listId) {
      this.listMenuId = listId;
    },
    hideListMenu() {
      this.listMenuId = "";
    },
    collapseGroup(groupId) {
      var tempArray = this.projectGroup;
      console.log(groupId);
      function findGroupItem(group) {
        return group.id === groupId;
      }
      const _index = this.projectGroup.findIndex(findGroupItem);

      if (tempArray[_index].collapse) {
        tempArray[_index].collapse = false;
      } else {
        tempArray[_index].collapse = true;
      }
      this.projectGroup = tempArray;
    },
    showMinusMember() {
      this.memPopoverTitle = this.$t("project.d_mem");
      this.showMemberPopover = true;
      this.memPopoverType = "minus";
      this.popMembers = this.members;
      this.memberSearch = "";
    },
    closeMemberPopover() {
      this.showMemberPopover = false;
      this.popMembers = new Array();
      this.memberSearch = "";
    },
    async updateMembers() {
      if (!this.checkedMembers.length) {
        console.log("this.checkedMembers", this.checkedMembers);
        this.$message(this.$t("notice.select_member"));
        return;
      }
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });
      const status = this.memPopoverType === "add" ? 1 : 0;
      let params = {
        request: "updatemembers",
        userid: this.$store.state.userid,
        projectid: this.$route.query.id,
        userids: this.checkedMembers,
        status: status,
      };
      try {
        let res = await projectDataRequest(params);
        if (res.status === 200) {
          this.getMembers();
          this.closeMemberPopover();
          this.checkedMembers = new Array();
          this.$helper.successNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    showCreateGroup() {
      this.groupPopoverTitle = this.$t("project.c_group");
      this.showGroupPopover = true;
      this.groupid = 0;
    },
    showEditGroup(group) {
      this.groupPopoverTitle = this.$t("project.e_group");
      this.groupid = group.id;
      this.group = {
        groupname: group.name,
      };
      this.showGroupPopover = true;
    },
    closeGroupPopover() {
      this.showGroupPopover = false;
      this.group = {};
    },
    submitGroup(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.updateGroup();
        } else {
          return false;
        }
      });
    },
    async updateGroup() {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      let params = {
        request: "groupedit",
        userid: this.dataUserID, // this.$store.state.userid,
        projectid: this.$route.query.id,
        groupname: this.group.groupname,
        groupid: this.groupid,
      };
      try {
        let res = await projectGroupRequest(params);
        if (res.status === 200) {
          this.closeGroupPopover();
          this.$helper.successNotify(res.message);
          this.getProjectGroup();
          this.getProjectInfo();
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    handleDeleteGroup(id) {
      MessageBox.confirm(this.$t("notice.p_confirm"), this.$t("notice.tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(() => {
          this.deleteGroup(id);
        })
        .catch(() => {});
    },
    async deleteGroup(id) {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      let params = {
        request: "groupremove",
        userid: this.$store.state.userid,
        projectid: this.$route.query.id,
        groupid: id,
      };
      try {
        let res = await projectGroupRequest(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          this.getProjectGroup();
          this.getProjectInfo();
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    getSelections() {
      let selections = [];
      if (this.$refs.multipleTable && this.$refs.multipleTable.length > 0) {
        this.$refs.multipleTable.forEach(function (table) {
          if (table.selection.length > 0) {
            table.selection.forEach(function (item) {
              selections.push(item.id);
            });
          }
        });
      }
      return selections;
    },
    clearSelections() {
      this.$refs.multipleTable.forEach(function (table) {
        table.clearSelection();
      });
    },
    async batchDownloadData() {
      const me = this;
      let selections = this.getSelections();
      if (selections.length === 0) {
        this.$helper.warningMessage(this.$t("project.text_selectData"));
        return false;
      }
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        userid: this.$store.state.userid,
        subjects: selections,
        projectid: this.$route.query.id,
        request: "batch_download_subject_list",
      };
      try {
        let res = await downloadPackage(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          eventHub.$emit("getProcessList");
          //换弹窗，链接打开下载url;
          // me.downloadurl = res.downloadurl;
          // me.centerDialogVisible = true;
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    async modalDownload(subject, type) {
      const me = this;
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.rowId = "";
      let params = {
        userid: this.$store.state.userid,
        subjectid: subject.id,
        projectid: this.$route.query.id,
        request: "download_" + type,
      };
      try {
        let res = await downloadPackage(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          eventHub.$emit("getProcessList");

          //换弹窗，链接打开下载url;
          // me.downloadurl = res.downloadurl;
          // me.centerDialogVisible = true;
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    showChangeGroup() {
      const selections = this.getSelections();
      if (selections.length === 0) {
        this.$helper.warningMessage(this.$t("notice.select_data"));
        return false;
      }
      this.showChangeGroupPopover = true;
    },
    hideChangeGroup() {
      this.showChangeGroupPopover = false;
      this.selectGroup = "";
    },
    async handleChangeGroup() {
      if (!this.selectGroup) {
        this.$helper.warningMessage(this.$t("notice.select_group"));
        return false;
      }
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      let selections = this.getSelections();
      let params = {
        request: "change_project_group",
        userid: this.$store.state.userid,
        change_project_id: this.$route.query.id,
        subjects: selections,
        change_group_id: this.selectGroup,
      };
      try {
        const res = await projectGroupRequest(params);
        if (res.status === 1 || res.status === 200) {
          this.$helper.successNotify(res.message);
          this.getProjectGroup();
          this.$nextTick(() => {
            this.clearSelections();
          });
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
      this.hideChangeGroup();
    },
    modifyInfo(subject) {
      this.showEditSubject = true;
      this.edit_subject = {
        subject_id: subject.id,
        subject_name: subject.subject_name,
        subject_age: subject.subject_age,
        subject_age_unit: subject.subject_age_unit,
        subject_gender: subject.subject_gender,
        subject_explain: subject.subject_explain,
        project_id: subject.project_id,
      };
    },
    async submitSubject(subject) {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      let params = {
        request: "edit",
        userid: this.$store.state.userid,
        ...subject,
      };
      try {
        let res = await dataRequest(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          this.getProjectGroup();
          this.showEditSubject = false;
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    handleDeleteSubject(subject) {
      MessageBox.confirm(this.$t("notice.p_confirm"), this.$t("notice.tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(() => {
          this.deleteSubject(subject);
        })
        .catch(() => {});
    },
    async deleteSubject(subject) {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      let params = {
        request: "remove",
        userid: this.$store.state.userid,
        subject_id: subject.id,
        projectid: subject.project_id || 0,
        groupid: subject.group_id || 0,
      };
      try {
        let res = await dataRequest(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          this.getProjectGroup();
          this.getProjectInfo();
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    showTagsModel(subject) {
      this.showTagPopover = true;
      this.checkedTags = subject.tags
        ? subject.tags.map(function (tag) {
            return tag.id;
          })
        : [];
      this.subjectid = subject.id;
      this.getTagList();
    },
    closeTagsModel() {
      this.showTagPopover = false;
      this.checkedTags = [];
      this.subjectid = "";
    },
    async getTagList() {
      let params = {
        request: "list",
        userid: this.$store.state.userid,
      };
      try {
        let res = await tagsRequest(params);
        if (res.status === 200) {
          this.tagsList = res.data.length > 0 ? res.data : [];
        }
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    },
    async addTag() {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      let params = {
        request: "insert",
        userid: this.$store.state.userid,
        name: this.tagname,
        description: "",
      };
      try {
        let res = await tagsRequest(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          this.tagname = "";
          this.getTagList();
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    async setTags() {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      let params = {
        request: "tagsubject",
        userid: this.$store.state.userid,
        subject_userid: this.$store.state.userid,
        subject_id: this.subjectid,
        tagids: this.checkedTags,
        tagname: "",
      };
      try {
        let res = await tagsRequest(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          this.getProjectGroup();
          this.closeTagsModel();
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    formatGender: function (row) {
      return row.subject_gender === "0"
        ? this.$t("male")
        : row.subject_gender === "1"
        ? this.$t("female")
        : "";
    },
    fliterMembers(str) {
      str = str.length ? str.toLowerCase() : str;
      if (this.memPopoverType == "add") {
        let popMembers = this.memberList.filter(
          (m) => m.realname.toLowerCase() == str || m.email.toLowerCase() == str
        );
        this.popMembers = Object.assign(this.popMembers, popMembers);
      } else {
        this.popMembers = this.members.filter(
          (m) =>
            m.realname.toLowerCase().search(str) >= 0 ||
            m.email.toLowerCase().search(str) >= 0
        );
      }
      //console.log(this.popMembers);
    },
    isProjectMember() {
      const login_userid = this.user.id;
      const project_members = this.members;
      let memberids = [];
      if (this.members.length) {
        memberids = this.members.filter((m) => m.id);
      }

      this.isMember = login_userid == this.dataUserID || memberids.length;
    },
  },
  watch: {
    // noTask(newVal, oldVal) {
    //   if (newVal) {
    //     clearInterval(this.interval);
    //   }
    // },
    // refresh(newVal, oldVal) {
    //   if (newVal) {
    //     this.getProjectGroup();
    //     this.getProjectInfo();
    //   }
    // },
    processList(newVal, oldVal) {
      if (newVal.length != oldVal.length) {
        this.getProjectGroup();
        this.getProjectInfo();
      }
    },
    memberSearch(n, o) {
      this.fliterMembers(n);
    },
    dataUserID(n, o) {
      this.isProjectMember();
    },
    members(n, o) {
      this.isProjectMember();
    },
  },
  beforeCreate() {
    _that = this;
  },
  filters: {
    formatStatus: function (status) {
      switch (status) {
        case "0":
          return _that.$t("package_status.processing");
        case "1":
          return _that.$t("package_status.completed");
        case "2":
          return _that.$t("package_status.failed");
        default:
          return "";
      }
    },
    formatMessage: function (status) {
      switch (status) {
        case "1":
          return _that.$t("package_status.failed_niix");
        case "2":
          return _that.$t("package_status.no_dicom");
        default:
          return _that.$t("package_status.failed");
      }
    },
  },
};
</script>

<style lang="less">
.table {
  border-bottom: 1px solid #dee2e6;
}

.dialog-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2003;
  overflow: auto;
  margin: 0;
  background: rgba(0, 0, 0, 0.6);

  .dialog-content {
    position: relative;
    margin: 15vh auto;
    border-radius: 3px;
    width: 600px;
    background: #fff;
  }

  .dialog-header {
    padding: 20px 10px;
    border-bottom: 1px solid #dee2e6;
  }

  .dialog-title {
    line-height: 24px;
    font-size: 18px;
    color: #303133;
  }

  .dialog-headerbtn {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
  }

  .dialog-body {
    padding: 30px 0;
    text-align: center;
  }
}

.tab {
  padding-left: 2rem;

  .tab-link {
    display: inline-block;
    padding: 0.25rem 1.5rem;

    font-size: 0.875rem;
    line-height: 1.5;
    color: #212529;
    cursor: pointer;

    &.tab-link-active {
      border-bottom: 2px solid #007bff;
    }
  }
}

.project-data-content {
  margin-right: 260px;

  .project-bread {
    margin-bottom: 0;
    padding: 0;
  }

  .data-process {
    margin-bottom: 10px;

    .data-item {
      padding: 0 5px 0 10px;
      height: 20px;
      line-height: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      &.item-primary {
        background: #e4eef9;
        color: #027bfe;
      }

      &.item-success {
        background: #e6f1ec;
        color: #26a745;
      }

      &.item-danger {
        background: #f4e8ec;
        color: #dc3545;
      }

      .close-btn {
        width: 20px;
        text-align: center;
        font-size: 18px;
        cursor: pointer;
      }
    }
  }

  .project-card {
    margin-bottom: 20px;
    background: #eee;
    transition: 0.3s ease-in-out;

    .fa-caret-down {
      transition: 0.3s ease-in-out;
    }

    &.group-collapse {
      .fa-caret-down {
        transform: rotate(-90deg);
      }
      .el-table {
        display: none;
      }
    }
    &.group-expand {
      .el-table {
        display: block;
      }
    }

    .el-checkbox {
      margin-bottom: 0 !important;
    }

    .el-table .cell {
      overflow: visible;
    }

    .el-table {
      overflow: visible;
    }

    .el-table__body-wrapper {
      overflow: visible !important;
    }
  }

  .card-name {
    line-height: 24px;
  }

  .project-name {
    font-size: 20px;
  }

  .owner-info {
    height: 30px;
    line-height: 30px;
  }
}

.project {
  .info-side {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 50px 12px 0;
    width: 260px;
    border-left: 1px solid #dee2e6;
    background: #fff;

    .side-header {
      margin-bottom: 10px;
    }

    .edit-btn {
      color: #067afd;
    }

    .side-content {
      border: 1px solid #dfdfdf;
      border-radius: 3px;
      background: #fff;
    }

    .info-item {
      padding: 7px 10px;
      border-bottom: 1px solid #dfdfdf;
      color: #6c757d;

      &:last-child {
        border-bottom: none;
      }
    }

    .analyse-wrapper {
      margin-top: 50px;
    }

    .analyse-link {
      color: #6c757d;
    }

    .item-title {
      margin-bottom: 5px;
      height: 16px;
      line-height: 16px;
    }

    .item-scroll {
      padding: 5px;
      height: 80px;
      overflow-y: auto;
      border: 1px solid #bfbfc1;
    }

    .member {
      margin-bottom: 5px;
      height: 16px;
      line-height: 16px;
    }
  }
}

.member-popover {
  .popover-head {
    font-size: 20px;
  }

  .member-content {
    padding: 15px 10px 10px;
  }

  .member-list {
    width: 100%;
    max-height: 228px;
    overflow-y: auto;
    border-top: 1px solid #bfbfc1;
    border-bottom: 1px solid #bfbfc1;
    background: #f8f9fa;
  }

  .member-item {
    padding: 0.5rem;
    border-top: 1px solid #bfbfc1;
    cursor: pointer;

    &:first-child {
      border-top: none;
    }

    &:hover {
      background: #f5fafd;
    }
  }
}

.group-popover {
  .popover-head {
    font-size: 20px;
  }

  .group-popo-lable {
    padding: 0 !important;
    line-height: 40px;
  }
}

.tags-popover {
  .tag-popover-body {
    padding-left: 80px;
  }

  .el-checkbox-group {
    width: 100%;
  }

  .tags-list {
    max-height: 200px;
    overflow-y: auto;
  }

  .tags-item {
    margin-bottom: 10px;
    padding-right: 10px;
    width: 25%;
  }

  .tag-name,
  .add-tag {
    height: 40px;
    line-height: 40px;
  }

  .add-tag {
    color: #007bff;
  }

  .tag-input {
    width: 260px;
  }
}

.service-content {
  padding-top: 20px;

  .service-item {
    flex: 0.5;
    margin-right: 30px;
    padding: 15px 30px;
    height: 302px;

    &:nth-child(2n + 2) {
      margin-right: 0;
    }

    .service-logo {
      margin-right: 30px;
      width: 100px;
      height: 100px;
      border-radius: 10px;
      background: #e5e5e5;
    }

    .service-info {
      margin-bottom: 20px;
      height: 100px;
    }

    .service-name {
      height: 40px;
      line-height: 40px;
      font-size: 26px;
      color: #212528;
    }

    .service-sub {
      margin-bottom: 15px;
      font-size: 14px;
      color: #6c757d;
    }

    .modality {
      margin-right: 50px;
    }

    .info-title {
      color: #6c757d;
    }

    .info-span {
      display: inline-block;
      border-radius: 3px;
      height: 16px;
      line-height: 16px;
      font-size: 12px;
      padding: 0 6px;
      color: #fff;
    }

    .modality-name {
      background: #14a2b8;
    }

    .serie-name {
      background: #26a745;
    }

    .service-desc {
      margin-bottom: 15px;
      height: 105px;
      border: 1px solid #dee2e6;
      overflow-y: auto;
    }
  }
}
</style>
