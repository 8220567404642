<template>
  <transition name="el-zoom-in-center">
    <div class="brain-popover" v-if="showPopover">
      <div class="popover-mask"></div>
      <div class="popover-content">
        <div class="popover-head">
          <span class="popover-title"> {{ $t("analyse.result") }} </span>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click.stop="closePopover"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="popover-body">
          <div class="tabel-content">
            <el-table
              ref="multipleTable"
              :data="dataList"
              :empty-text="$t('no_data')"
              stripe
              header-row-class-name=""
            >
              <el-table-column
                prop="subject_number"
                :label="$t('_data.p_name')"
              ></el-table-column>
              <el-table-column
                prop="task_name"
                :label="$t('analyse.task_name')"
              ></el-table-column>
              <el-table-column
                prop="series_date"
                :label="$t('analyse.series_time')"
                width="160"
              ></el-table-column>

              <el-table-column
                prop="date_created"
                :label="$t('s_time')"
                width="160"
              ></el-table-column>
              <el-table-column
                prop="date_update"
                :label="$t('u_time')"
                width="160"
              ></el-table-column>

              <el-table-column prop="status" :label="$t('analyse.status')">
                <template v-slot="scope">
                  <span :class="statusColClass(scope.row.status)">{{
                    scope.row.status | formatStatus
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('voi_edit')" align="center">
                <template slot-scope="scope">
                  <a
                    v-if="scope.row.status == '3' && scope.row.use_voi_editor"
                    target="_blank"
                    :href="
                      url_voiviewer +
                      '?service_url=' +
                      url_adaptorapi +
                      '&backendapi=' +
                      url_backendapi +
                      '&keyid=' +
                      scope.row.task_id +
                      '&app_id=' +
                      scope.row.app_id +
                      '&datatype=brainlabel&subject_number=' +
                      scope.row.subject_number +
                      '&subject_age=' +
                      scope.row.subject_age +
                      '&subject_age_unit=' +
                      scope.row.subject_age_unit +
                      '&subject_gender=' +
                      scope.row.subject_gender +
                      '&language=' +
                      (lang == 'cn' ? 'zh' : lang) +
                      '&dataowner=' +
                      (scope.row.data_ownerid == user.id ? 'true' : 'false') +
                      '&uid=' +
                      scope.row.data_ownerid +
                      '&user=' +
                      user.id +
                      '&image_data=' +
                      scope.row.image_data +
                      '&static_url=' +
                      scope.row.static_url +
                      '&user_role=' +
                      user.role +
                      '&token=' +
                      token
                    "
                    >{{ $t("voi_edit") }}</a
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="subject_number"
                :label="$t('analyse.result')"
              >
                <template v-slot="scope">
                  <a
                    v-if="scope.row.url_t1viewer"
                    :href="
                      scope.row.url_t1viewer +
                      '&language=' +
                      lang +
                      '&timer=' +
                      timer
                    "
                    target="_blank"
                    >{{ $t("analyse.viewer") }}</a
                  >

                  <a
                    v-if="scope.row.url_ptviewer"
                    :href="
                      scope.row.url_ptviewer +
                      '&language=' +
                      (lang == 'cn' ? 'zh' : lang) +
                      '&timer=' +
                      timer
                    "
                    target="_blank"
                    >{{ $t("analyse.viewer") }}</a
                  >

                  <a
                    v-if="scope.row.url_dtiviewer"
                    :href="
                      scope.row.url_dtiviewer +
                      '&language=' +
                      (lang == 'zh' ? 'cn' : lang) +
                      '&subject=' +
                      scope.row.subject_number +
                      '&age=' +
                      scope.row.subject_age +
                      '&age_unit=' +
                      scope.row.subject_age_unit +
                      '&gender=' +
                      scope.row.subject_gender +
                      '&timer=' +
                      timer
                    "
                    target="_blank"
                    >{{ $t("analyse.viewer") }}</a
                  >

                  <a
                    v-if="scope.row.url_flairviewer"
                    :href="
                      scope.row.url_flairviewer +
                      '&language=' +
                      (lang == 'zh' ? 'cn' : lang) +
                      '&subject=' +
                      scope.row.subject_number +
                      '&age=' +
                      scope.row.subject_age +
                      '&age_unit=' +
                      scope.row.subject_age_unit +
                      '&gender=' +
                      scope.row.subject_gender +
                      '&timer=' +
                      timer
                    "
                    target="_blank"
                    >{{ $t("analyse.viewer") }}</a
                  >

                  <a
                    v-if="scope.row.url_multimodalityviewer"
                    :href="
                      scope.row.url_multimodalityviewer +
                      '&language=' +
                      (lang == 'zh' ? 'cn' : lang) +
                      '&subject=' +
                      scope.row.subject_number +
                      '&age=' +
                      scope.row.subject_age +
                      '&age_unit=' +
                      scope.row.subject_age_unit +
                      '&gender=' +
                      scope.row.subject_gender +
                      '&timer=' +
                      timer
                    "
                    target="_blank"
                    >{{ $t("analyse.viewer") }}</a
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="address"
                :label="$t('operate')"
                align="right"
              >
                <template v-slot="scope">
                  <el-dropdown trigger="click">
                    <span class="el-dropdown-link cursor">
                      <i class="fa fa-bars mr-1"></i>
                      <i class="fa fa-angle-down"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-if="
                          scope.row.status === '3' &&
                          scope.row.download_type.statistic
                        "
                        @click.native="downloadStatistic(scope.row.id)"
                        ><i class="fa fa-download text-success"></i>
                        {{ $t("analyse.download_statistic") }}</el-dropdown-item
                      >
                      <el-dropdown-item
                        v-if="
                          scope.row.status === '3' &&
                          scope.row.download_type.result
                        "
                        @click.native="downloadResult(scope.row.id)"
                      >
                        <i class="fa fa-download text-success"></i>
                        {{ $t("analyse.download_result") }}</el-dropdown-item
                      >
                      <el-dropdown-item
                        icon="el-icon-delete"
                        class="text-danger"
                        @click.native="deleteAnalyse(scope.row.id)"
                        divided
                      >
                        {{ $t("delete") }}</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div class="clearfix mt-3 mb-3">
            <div class="float-left">
              <pagination
                v-if="totalCount > 0"
                :pageSize="pageSize"
                :layout="layout"
                :currentPage="currentPage"
                :total="totalCount"
                @goToPage="handleCurrentChange"
              />
            </div>
          </div>
        </div>

        <div class="popover-footer clearfix">
          <div class="float-right">
            <button
              type="button"
              class="btn btn-grey btn-sm ml10"
              @click.stop="closePopover"
            >
              {{ $t("close") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {
  analyseRequest,
  deleteAnalyse,
  downloadAnalysisData,
  getVoiViewAddress,
} from "@/api/api";
import pagination from "@/components/pager";
import { paginationMixin } from "@/utils/mixin";
import { MessageBox, Loading } from "element-ui";

import { mapState, mapGetters } from "vuex";
let _that;

export default {
  mixins: [paginationMixin],
  components: {
    pagination,
  },

  props: {
    showPopover: {
      type: Boolean,
      default: false,
    },
    Params: {
      type: Object,
      default: () => {
        return {
          project_id: null,
          app_id: null,
          data_type: null,
        };
      },
    },
  },
  data() {
    return {
      searchForm: {
        search: "",
        series: "",
      },
      modality: "all",
      seriesList: new Array(),
      dataList: new Array(),
      appModality: new Array(),
      showSearchDrawer: false,
      clearable: false,
      rowId: "",
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : {},
      token: localStorage.getItem("token") || "",
      //subject_id: this.$route.query.subject_id || "",
      //app_id: this.$route.query.app_id || "",
      pageSize: 15,
      totalCount: 0,
      //lang: this.$store.state.language || "zh",
      menuid: null,
      timer: new Date().getTime(),
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.language,
      url_voiviewer: (state) => state.url_voiviewer, //:28803
      url_backendapi: (state) => state.url_backendapi, //:28801
      url_adaptorapi: (state) => state.url_adaptorapi, //:28000
    }),
  },
  created() {},
  watch: {
    Params(newval, oldval) {
      newval && this.getAnalyseList();
    },
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getAnalyseList();
    },
    closePopover() {
      this.$emit("closePorjectTaskListPopover");
    },
    async getAnalyseList() {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });
      let modality = this.modality === "all" ? "" : this.modality;
      let params = {
        ...this.searchForm,
        modality: modality,
        projectid: this.Params.project_id,
        app_id: this.Params.app_id,
        limit: this.pageSize,
        offset: this.currentPage - 1,
      };
      try {
        let res = await analyseRequest(params);
        if (res.status === 200) {
          this.dataList = res.data.length > 0 ? res.data : [];
          this.totalCount = res.total;
          this.appModality = res.app_modality || [];
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },

    formatGender: function (row) {
      return row.subject_gender === "0"
        ? this.$t("male")
        : row.subject_gender === "1"
        ? this.$t("female")
        : "";
    },
    statusColClass: function (status) {
      switch (status) {
        case "0":
        case "1":
          return "text-muted";
        case "2":
          return "text-primary";
        case "3":
          return "text-success";
        default:
          return "text-danger";
      }
    },
    showMenu(rowData) {
      this.rowId = rowData.id;
    },
    hideMenu() {
      this.rowId = "";
    },
    deleteAnalyse(id) {
      MessageBox.confirm(this.$t("notice.p_confirm"), this.$t("notice.tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(() => {
          this.deleteAnalyseFunc(id);
        })
        .catch(() => {});
    },
    async deleteAnalyseFunc(id) {
      this.rowId = "";
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      try {
        let res = await deleteAnalyse({
          id: id,
        });
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          this.getAnalyseList();
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    async downloadStatistic(id) {
      let params = {
        id: id,
        type: "statistic",
      };
      console.log(id);
      try {
        let res = await downloadAnalysisData(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          window.open(res.data);
        } else {
          this.$helper.errNotify(res.message);
        }
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    },
    async downloadResult(id) {
      let params = {
        id: id,
        type: "result",
      };
      try {
        let res = await downloadAnalysisData(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          window.open(res.data);
        } else {
          this.$helper.errNotify(res.message);
        }
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    },
    async getVoiAddress(rowData) {
      let params = {
        id: rowData.id,
        from: "brainlabel",
        language: localStorage.getItem("lang") || "zh",
      };
      try {
        let res = await getVoiViewAddress(params);
        if (res.status === 200) {
          window.open(res.data);
        } else {
          this.$helper.errNotify(res.message);
        }
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    },
  },
  mounted() {
    window.addEventListener("click", this.hideMenu, false);
  },
  beforeCreate() {
    _that = this;
  },
  filters: {
    formatStatus: function (status) {
      switch (status) {
        case "0":
          return _that.$t("task_status.created");
        case "1":
          return _that.$t("task_status.wait");
        case "2":
          return _that.$t("task_status.processing");
        case "3":
          return _that.$t("task_status.completed");
        case "4":
          return _that.$t("task_status.failed");
        case "5":
          return _that.$t("task_status.timeout");
        case "6":
          return _that.$t("task_status.license_expired");
        case "7":
          return _that.$t("task_status.license_not_found");
        case "8":
          return _that.$t("task_status.license_error");
        case "12":
          return _that.$t("task_status.invalid");
        default:
          return " ";
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("click", this.hideMenu);
  },
};
</script>

<style lang="less" scoped>
.brain-popover .popover-content {
  width: 80%;
  margin: 50px auto 0;
}

.table {
  border-bottom: 1px solid #dee2e6;
}

.data-manage {
  .main-content {
    transition: 0.3s ease-in;
  }

  .main-content.active {
    width: calc(~"100% - 220px");
    // transform: translate(-220px);
    // margin-right: 220px;
  }

  .el-checkbox {
    margin-bottom: 0;
    // color: #fff;
  }

  .el-table .cell {
    overflow: visible;
  }

  .el-table {
    overflow: visible;
  }

  .el-table__body-wrapper {
    overflow: visible !important;
  }
}

.data-manager-popover {
  .popover-content {
    margin: 50px auto 0;
    width: 700px;
    background: #fff;
  }
}

.popover-body {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(95vh - 190px);
}

.dialog-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2003;
  overflow: auto;
  margin: 0;
  background: rgba(0, 0, 0, 0.6);

  .dialog-content {
    position: relative;
    margin: 15vh auto;
    border-radius: 3px;
    width: 600px;
    background: #fff;
  }

  .dialog-header {
    padding: 20px 10px;
    border-bottom: 1px solid #dee2e6;
  }

  .dialog-title {
    line-height: 24px;
    font-size: 18px;
    color: #303133;
  }

  .dialog-headerbtn {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
  }

  .dialog-body {
    padding: 30px 0;
    text-align: center;
  }
}
</style>
