var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"el-zoom-in-center"}},[(_vm.showPopover)?_c('div',{staticClass:"brain-popover"},[_c('div',{staticClass:"popover-mask"}),_c('div',{staticClass:"popover-content"},[_c('div',{staticClass:"popover-head"},[_c('span',{staticClass:"popover-title"},[_vm._v(" "+_vm._s(_vm.$t("analyse.result"))+" ")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":function($event){$event.stopPropagation();return _vm.closePopover.apply(null, arguments)}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"popover-body"},[_c('div',{staticClass:"tabel-content"},[_c('el-table',{ref:"multipleTable",attrs:{"data":_vm.dataList,"empty-text":_vm.$t('no_data'),"stripe":"","header-row-class-name":""}},[_c('el-table-column',{attrs:{"prop":"subject_number","label":_vm.$t('_data.p_name')}}),_c('el-table-column',{attrs:{"prop":"task_name","label":_vm.$t('analyse.task_name')}}),_c('el-table-column',{attrs:{"prop":"series_date","label":_vm.$t('analyse.series_time'),"width":"160"}}),_c('el-table-column',{attrs:{"prop":"date_created","label":_vm.$t('s_time'),"width":"160"}}),_c('el-table-column',{attrs:{"prop":"date_update","label":_vm.$t('u_time'),"width":"160"}}),_c('el-table-column',{attrs:{"prop":"status","label":_vm.$t('analyse.status')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{class:_vm.statusColClass(scope.row.status)},[_vm._v(_vm._s(_vm._f("formatStatus")(scope.row.status)))])]}}],null,false,2244737677)}),_c('el-table-column',{attrs:{"label":_vm.$t('voi_edit'),"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.status == '3' && scope.row.use_voi_editor)?_c('a',{attrs:{"target":"_blank","href":_vm.url_voiviewer +
                    '?service_url=' +
                    _vm.url_adaptorapi +
                    '&backendapi=' +
                    _vm.url_backendapi +
                    '&keyid=' +
                    scope.row.task_id +
                    '&app_id=' +
                    scope.row.app_id +
                    '&datatype=brainlabel&subject_number=' +
                    scope.row.subject_number +
                    '&subject_age=' +
                    scope.row.subject_age +
                    '&subject_age_unit=' +
                    scope.row.subject_age_unit +
                    '&subject_gender=' +
                    scope.row.subject_gender +
                    '&language=' +
                    (_vm.lang == 'cn' ? 'zh' : _vm.lang) +
                    '&dataowner=' +
                    (scope.row.data_ownerid == _vm.user.id ? 'true' : 'false') +
                    '&uid=' +
                    scope.row.data_ownerid +
                    '&user=' +
                    _vm.user.id +
                    '&image_data=' +
                    scope.row.image_data +
                    '&static_url=' +
                    scope.row.static_url +
                    '&user_role=' +
                    _vm.user.role +
                    '&token=' +
                    _vm.token}},[_vm._v(_vm._s(_vm.$t("voi_edit")))]):_vm._e()]}}],null,false,1254326790)}),_c('el-table-column',{attrs:{"prop":"subject_number","label":_vm.$t('analyse.result')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.url_t1viewer)?_c('a',{attrs:{"href":scope.row.url_t1viewer +
                    '&language=' +
                    _vm.lang +
                    '&timer=' +
                    _vm.timer,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("analyse.viewer")))]):_vm._e(),(scope.row.url_ptviewer)?_c('a',{attrs:{"href":scope.row.url_ptviewer +
                    '&language=' +
                    (_vm.lang == 'cn' ? 'zh' : _vm.lang) +
                    '&timer=' +
                    _vm.timer,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("analyse.viewer")))]):_vm._e(),(scope.row.url_dtiviewer)?_c('a',{attrs:{"href":scope.row.url_dtiviewer +
                    '&language=' +
                    (_vm.lang == 'zh' ? 'cn' : _vm.lang) +
                    '&subject=' +
                    scope.row.subject_number +
                    '&age=' +
                    scope.row.subject_age +
                    '&age_unit=' +
                    scope.row.subject_age_unit +
                    '&gender=' +
                    scope.row.subject_gender +
                    '&timer=' +
                    _vm.timer,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("analyse.viewer")))]):_vm._e(),(scope.row.url_flairviewer)?_c('a',{attrs:{"href":scope.row.url_flairviewer +
                    '&language=' +
                    (_vm.lang == 'zh' ? 'cn' : _vm.lang) +
                    '&subject=' +
                    scope.row.subject_number +
                    '&age=' +
                    scope.row.subject_age +
                    '&age_unit=' +
                    scope.row.subject_age_unit +
                    '&gender=' +
                    scope.row.subject_gender +
                    '&timer=' +
                    _vm.timer,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("analyse.viewer")))]):_vm._e(),(scope.row.url_multimodalityviewer)?_c('a',{attrs:{"href":scope.row.url_multimodalityviewer +
                    '&language=' +
                    (_vm.lang == 'zh' ? 'cn' : _vm.lang) +
                    '&subject=' +
                    scope.row.subject_number +
                    '&age=' +
                    scope.row.subject_age +
                    '&age_unit=' +
                    scope.row.subject_age_unit +
                    '&gender=' +
                    scope.row.subject_gender +
                    '&timer=' +
                    _vm.timer,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("analyse.viewer")))]):_vm._e()]}}],null,false,639891506)}),_c('el-table-column',{attrs:{"prop":"address","label":_vm.$t('operate'),"align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-dropdown',{attrs:{"trigger":"click"}},[_c('span',{staticClass:"el-dropdown-link cursor"},[_c('i',{staticClass:"fa fa-bars mr-1"}),_c('i',{staticClass:"fa fa-angle-down"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[(
                        scope.row.status === '3' &&
                        scope.row.download_type.statistic
                      )?_c('el-dropdown-item',{nativeOn:{"click":function($event){return _vm.downloadStatistic(scope.row.id)}}},[_c('i',{staticClass:"fa fa-download text-success"}),_vm._v(" "+_vm._s(_vm.$t("analyse.download_statistic")))]):_vm._e(),(
                        scope.row.status === '3' &&
                        scope.row.download_type.result
                      )?_c('el-dropdown-item',{nativeOn:{"click":function($event){return _vm.downloadResult(scope.row.id)}}},[_c('i',{staticClass:"fa fa-download text-success"}),_vm._v(" "+_vm._s(_vm.$t("analyse.download_result")))]):_vm._e(),_c('el-dropdown-item',{staticClass:"text-danger",attrs:{"icon":"el-icon-delete","divided":""},nativeOn:{"click":function($event){return _vm.deleteAnalyse(scope.row.id)}}},[_vm._v(" "+_vm._s(_vm.$t("delete")))])],1)],1)]}}],null,false,1752325368)})],1)],1),_c('div',{staticClass:"clearfix mt-3 mb-3"},[_c('div',{staticClass:"float-left"},[(_vm.totalCount > 0)?_c('pagination',{attrs:{"pageSize":_vm.pageSize,"layout":_vm.layout,"currentPage":_vm.currentPage,"total":_vm.totalCount},on:{"goToPage":_vm.handleCurrentChange}}):_vm._e()],1)])]),_c('div',{staticClass:"popover-footer clearfix"},[_c('div',{staticClass:"float-right"},[_c('button',{staticClass:"btn btn-grey btn-sm ml10",attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.closePopover.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("close"))+" ")])])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }